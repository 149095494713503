
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import SelectSearch from "@/app/ui/components/select-search-origin-destination/index.vue";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import AdvancedFilter from "@/app/ui/components/advanced-filter/index.vue";

@Options({
  inheritAttrs: false,
  props: {
    isSelectTypeDisable: {
      default: false,
      type: Boolean
    },
    currentRoutes: {
      default: "",
      type: String
    },
    selectSearchOriginNotFound: {
      default: false,
      type: Boolean
    },
    selectSearchDestinationNotFound: {
      default: false,
      type: Boolean
    },
    isSelectSearch: {
      default: false,
      type: Boolean
    },
    placeholderOrigin: {
      default: "",
      type: String
    },
    searchValueOrigin: {
      default: "",
      type: String
    },
    selectSearchDataOrigin: {
      default: [],
      type: Array
    },
    onSelectSearchOrigin: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onOpenSelectSearchOrigin: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onCloseSelectSearchOrigin: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onChooseSelectSearchOrigin: {
      default: Function as PropType<() => void>,
      type: Function
    },
    statusSelectSearchOrigin: {
      default: false,
      type: Boolean
    },
    placeholderDestination: {
      default: "",
      type: String
    },
    searchValueDestination: {
      default: "",
      type: String
    },
    selectSearchDataDestination: {
      default: [],
      type: Array
    },
    onSelectSearchDestination: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onOpenSelectSearchDestination: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onCloseSelectSearchDestination: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onChooseSelectSearchDestination: {
      default: Function as PropType<() => void>,
      type: Function
    },
    statusSelectSearchDestination: {
      default: false,
      type: Boolean
    },
    isSearchable: {
      default: false,
      type: Boolean
    },
    isSelectType: {
      default: false,
      type: Boolean
    },
    isSelectStatus: {
      default: false,
      type: Boolean
    },
    isSelectReference: {
      default: false,
      type: Boolean
    },
    isSelectKurs: {
      default: false,
      type: Boolean
    },
    isButtonAction: {
      default: false,
      type: Boolean
    },
    searchAction: {
      default: Function as PropType<() => void>,
      type: Function
    },
    placeholder: {
      default: "",
      type: String
    },
    clearSearch: {
      default: Function as PropType<() => void>,
      type: Function
    },
    searchValue: {
      default: "",
      type: String
    },
    typeData: {
      default: [{ name: "", value: "" }],
      type: Array
    },
    onOpenTypeSelect: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onCloseTypeSelect: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onSelectType: {
      default: Function as PropType<() => void>,
      type: Function
    },
    valueType: {
      default: "",
      type: String
    },
    typeSelect: {
      default: false,
      type: Boolean
    },
    statusSelect: {
      default: false,
      type: Boolean
    },
    statusData: {
      default: [{ name: "", value: "" }],
      type: Array
    },
    onOpenStatusSelect: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onCloseStatusSelect: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onSelectStatus: {
      default: Function as PropType<() => void>,
      type: Function
    },
    valueStatus: {
      default: "",
      type: String
    },
    referenceSelect: {
      default: false,
      type: Boolean
    },
    isDisable: {
      default: false,
      type: Boolean
    },
    referenceData: {
      default: [{ name: "", value: "" }],
      type: Array
    },
    onOpenReferenceSelect: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onCloseReferenceSelect: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onSelectReference: {
      default: Function as PropType<() => void>,
      type: Function
    },
    valueReference: {
      default: "",
      type: String
    },
    kursAwalSelect: {
      default: false,
      type: Boolean
    },
    kursAwalData: {
      default: [{ name: "", value: "" }],
      type: Array
    },
    onOpenKursAwalSelect: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onCloseKursAwalSelect: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onSelectKursAwal: {
      default: Function as PropType<() => void>,
      type: Function
    },
    valueKursAwal: {
      default: "",
      type: String
    },
    kursTukarSelect: {
      default: false,
      type: Boolean
    },
    kursTukarData: {
      default: [{ name: "", value: "" }],
      type: Array
    },
    onOpenKursTukarSelect: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onCloseKursTukarSelect: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onSelectKursTukar: {
      default: Function as PropType<() => void>,
      type: Function
    },
    valueKursTukar: {
      default: "",
      type: String
    },
    buttonAction: {
      default: Function as PropType<() => void>,
      type: Function
    },
    buttonText: {
      default: "",
      type: String
    },
    isLoading: {
      default: false,
      type: Boolean
    },
    isSelectRenewal: {
      default: false,
      type: Boolean
    },
    statusSelectRenewal: {
      default: false,
      type: Boolean
    },
    statusDataRenewal: {
      default: [{ name: "", value: "" }],
      type: Array
    },
    onOpenStatusSelectRenewal: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onCloseStatusSelectRenewal: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onSelectStatusRenewal: {
      default: Function as PropType<() => void>,
      type: Function
    },
    valueStatusRenewal: {
      default: "",
      type: String
    },
    isButtonDownload: {
      default: false,
      type: Boolean
    },
    buttonDownloadAction: {
      default: Function as PropType<() => void>,
      type: Function
    },
    isSelectSearchSingle: {
      default: false,
      type: Boolean
    },
    placeholderSelectSearchSingle: {
      default: "",
      type: String
    },
    searchValueSelectSearchSingle: {
      default: "",
      type: String
    },
    selectSearchSingleData: {
      default: [],
      type: Array
    },
    onSelectSearchSingle: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onOpenSelectSearchSingle: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onCloseSelectSearchSingle: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onChooseSelectSearchSingle: {
      default: Function as PropType<() => void>,
      type: Function
    },
    statusSelectSearchSingle: {
      default: false,
      type: Boolean
    },
    isAdvancedFilter: {
      default: false,
      type: Boolean
    },
    isFilterPeriode: {
      default: true,
      type: Boolean
    },
    isFilterOriginDestination: {
      default: false,
      type: Boolean
    },
    isFilterClientPartner: {
      default: false,
      type: Boolean
    },
    isFilterStatus: {
      default: true,
      type: Boolean
    },
    isFilterProduct: {
      default: true,
      type: Boolean
    },
    isFilterCOD: {
      default: true,
      type: Boolean
    },
    isFilterAsuransi: {
      default: true,
      type: Boolean
    },
    isFilterDisabled: {
      default: false,
      type: Boolean
    },
    advancedFilterData: {
      type: Object
    },
    onChangeAdvancedFilter: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onOpenedAdvancedFilter: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onResetAdvancedFilter: {
      default: Function as PropType<() => void>,
      type: Function
    },
    addSumFilter: {
      default: 0,
      type: Number
    },
    ableToFilter: {
      default: false,
      type: Boolean
    },
    selectSelectSearchSingle: {
      default: false,
      type: Boolean
    },
    isBorderBottom: {
      default: true,
      type: Boolean
    }
  },
  components: {
    SelectSearch,
    Tooltip,
    AdvancedFilter
  }
})
export default class Header extends Vue {
  isOpenTooltip = false;
  onOpenTooltip(value: boolean) {
    this.isOpenTooltip = value;
  }

  // show filter
  isFilterShow = false;
  setFilterShow(value: boolean) {
    this.isFilterShow = value;
    this.$emit("onToggleShowFilter", value);
  }
}
